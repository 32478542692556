import React from 'react';

const Footer = () => {
  return ( 
    <footer className="footer">
     <p>Oasis Hoteles & Resorts </p>
    </footer>
  );
}
 
export default Footer;