import React from "react";
import logo from "../../logocorporativo.png";
import { deleteAllCookies, checkCookie } from "../../utils/cookies";
import { Redirect } from "react-router";

const logout = () => {
  deleteAllCookies()
  document.location.reload()
}

const Header = () => {
  return (
    <header className="header">
      <div className="header__main">
        <div className="header__brand">
          <img src={logo} alt="" />
        </div>
        <div className="header__title">
          <h1>Generador de Firmas ● OASIS HOTELES & RESORTS</h1>
        </div>
      </div>
      {checkCookie() != null && (
        <button
          type="button"
          className="btn btn-reset header__button"
          onClick={logout}
        >
          Salir
        </button>
      )}
    </header>
  );
};

export default Header;
